import {LangType} from '@web/core/types/api/lang.type'

export interface LangModel {
  id: LangType;
  name: string;
  shortName: string;
}

export const LANGS: LangModel[] = [
  {id: 'kk', name: 'Қазақша', shortName: 'Қаз'},
  {id: 'ru', name: 'Русский', shortName: 'Рус'},
]
